* {
  box-sizing: border-box;
}

.diagnosis-flex {
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow: hidden;
}

.diagnosis-flex .input, .textarea {
  padding: 8px;
}

.diagnosis-card,
.patient-card {
  border: 1px solid gray;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #e7effa;
}

/* Flex basis and max-width are set for large screens */
.diagnosis-flex-patient {
  flex: 1 1 30%;
  max-width: 30%;
}

.diagnosis-flex-other {
  flex: 1 1 70%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.diagnosis-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.diagnosis-row {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 10px;
}

.textarea, .input {
  width: 100%;
  max-width: 100%;
}

.patient-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.patient-details label {
  margin-bottom: 5px;
}

.patient-buttons {
  margin-top: 1rem;
}

.patient-buttons a {
  display: block;
  width: 100%;
  margin: 5px 0;
}

.patient-img {
  text-align: center;
  margin-bottom: 1rem;
}

.patient-img img {
  width: 80px;
}

.other-top-section-1, .other-top-section-2 {
  flex: 1;
}

.other-top-section-2 {
  display: flex;
  justify-content: flex-end;
}

.invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545;
  display: inline;
}

.medicine-fields {
  display: flex;
  gap: 1rem;
}

.plus-icon {
  padding-top: 6px;
}

.medicine-input {
  flex: 2;
}

.new-custom-checkbox-container {
  display: flex;
  flex-direction: column;
}

.new-custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.new-custom-checkbox input {
  display: none;
}

.new-custom-checkbox-mark {
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-custom-checkbox input:checked + .new-custom-checkbox-mark {
  background-color: #417dbe;
  color: #fff;
}

.new-custom-checkbox input:checked + .new-custom-checkbox-mark::after {
  content: "✔";
  font-size: 14px;
}

.new-custom-checkbox-container .input[type="date"] {
  margin-left: 10px;
}

.appointment-header {
  background: #417EBF;
  padding: 1rem;
  color: white;
}


/* Responsive adjustments for tablets */
@media (max-width: 992px) {
  .diagnosis-flex {
    flex-direction: column;
  }
  .diagnosis-flex-patient, .diagnosis-flex-other {
    max-width: 100%;
    flex: none;
  }
  .other-top-section-2 {
    flex-direction: column;
  }

  .other-top-section-2 button {
    margin-top: 10px;
  }
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
  .patient-card, .diagnosis-card {
    padding: 1rem;
  }
  .patient-img img {
    width: 60px;
  }
  .patient-buttons, .other-top-section-2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .other-top-section-2 button {
    width: 100%;
    margin-top: 0;
  }
}
