.new-table {
  border-bottom: 1px solid gray;
  width: 100%;
  border-collapse: collapse;
}

.new-table-head {
  border-bottom: 1px solid gray;
}

.new-table-row {
  border-bottom: 1px solid gray;
  color: #616163;
}

.new-table-cell {
  border-bottom: 1px solid gray;
  padding: 7px;
}

.new-table-header {
  font-size: 1rem;
  font-weight: bold;
}

.bg-success {
  background-color: green;
}

.bg-danger {
  background-color: red;
}
