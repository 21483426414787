.report-container {
  position: relative;
  font-family: 'Arial', sans-serif;
  color: #333;
  max-width: 800px;
  margin: 2rem auto;
  padding: 20px;
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  overflow: hidden;
}

.report-container::before {
  content: "CONFIDENTIAL";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 100px;
  color: red;
  opacity: 0.2;
  white-space: nowrap;
  z-index: 0;
}

.doctor-report-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.doctor-report-logo {
  width: 6rem;
  height: 6rem;
  margin-right: 20px;
}

.hospital-details {
  text-align: left;
  z-index: 1;
}

.hospital-details h1 {
  font-size: 24px;
  margin: 0;
}

.hospital-details h2 {
  font-size: 20px;
  margin: 0;
}

.section-content {
  margin-bottom: 20px;
  z-index: 1;
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.doctor-report-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  z-index: 1;
}

.doctor-report-table th, .doctor-report-table td {
  padding: 8px;
  border: 1px solid gray;
}

.doctor-report-table th {
  background-color: #bdd4ee;
}

.doctor-report-table td ul {
  padding-left: 20px;
}

.doctor-report-table td ul li {
  margin-bottom: 5px;
}

.inner-table {
  width: 100%;
  border-collapse: collapse;
}

.inner-table th, .inner-table td {
  padding: 5px;
  border: 1px solid gray;
}

.inner-table th {
  background-color: #eef5ff;
}

.footer-note {
  margin-top: 20px;
  text-align: center;
  z-index: 1;
}

.footer-note p {
  margin: 0;
  font-size: 12px;
  color: #555;
}

.download-btn-container {
  text-align: center;
  margin-bottom: -1rem;
  margin-top: 2rem;
  cursor: pointer;
}